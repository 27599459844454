


a.news-list-item {
  display: block;
  text-decoration: none;
  margin-bottom: 3rem;
  
  .news-list-title {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: color(zomegraydark);
    margin-bottom: .6rem;
  }
  
  .news-list-type {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    color: color(zomegreen);
  }
  
  .news-list-date {
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: uppercase;
    color: color(zomegray);
  }

  @media only screen and (min-width: $tablet-min) {
    
    &:hover .news-list-title {
      text-decoration: underline;
    }
  }
  
}

#news-index .views-responsive-grid,
#news-detail-page .views-responsive-grid,
.news-list-3col .views-responsive-grid{
  
  @media only screen and (min-width: $tablet-min) {
  display: table;
  margin: 0 -20px;
  
    .views-row {
      display: table-row;
      table-layout: fixed;
    }
    
    .views-column {
      display: table-cell;
      table-layout: fixed;
      width: 1%;
    }
    
    a.news-list-item {
      padding: 0 20px;
    } 
  }
}