

a.news-back {
  display: inline-block;
  color: color(zomegray);
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 40px;
  
  &:hover {
    color: color(zomegreen);
  }
}

.news-column {

  @media only screen and (min-width: $desktop-min) {
    width: 600px;
    float: right;
    margin-right: 90px;
  }
  
  h1 {
    margin-bottom: 1rem;
  }
  
  img {
    display: block;
    width: 100% !important;
    height: auto !important;
  }
  
  .news-type {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    color: color(zomegreen);
  }
  
  .news-date {
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: uppercase;
    color: color(zomegray);
  }
  
  .news-body {
    margin-top: 50px;
  
    p {
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-bottom: 1.5rem;
    }
    
    .responsive-video {
      margin-top: 40px;
    }
  }
}