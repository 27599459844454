// Headings
//
// All HTML headings, `<h1>` through `<h6>`, are available. `%h1` through `%h6`
// classes are also available, for when you want to match the font styling of a
// heading but still want your text to be displayed inline.
//
// Markup: headings-h.twig
//
// Style guide: base.headings

// Address variable `h1` font-size and margin within `section` and `article`
// contexts in Firefox 4+, Safari, and Chrome.
h1,
%h1 {
  font-weight: 300;
  color: color(zomegray);
  font-size: 3rem;
  line-height: 3.6rem;
  margin: 0;
  margin-bottom: 50px;
}

h2,
%h2 {
  font-weight: 300;
  color: color(zomegray);
  font-size: 3rem;
  line-height: 3.6rem;
  text-align: right;
  margin: 0;
  margin-bottom: 30px;
}

h3,
%h3 {
  @include type-layout(l, 1.5);
  @include margin-top(1, l);
  @include margin-bottom(1, l);
}

h4,
%h4 {
  @include type-layout(m, 1);
  @include margin-top(1, m);
  @include margin-bottom(1, m);
}

h5,
%h5 {
  @include type-layout(s, 1);
  @include margin-top(1, s);
  @include margin-bottom(1, s);
}

h6,
%h6 {
  @include type-layout(xs, 1);
  @include margin-top(1, xs);
  @include margin-bottom(1, xs);
}
