


.footer {
  
  .menu-name-main-menu ul {
    margin: 0;
    padding: 0;
  }
  
  .menu-name-main-menu > ul {
    display: flex;
    flex-direction: vertical;
  }
  
  .menu-name-main-menu > ul > li.header-menu-column {
    margin-bottom: 30px;
    min-width: 130px;
    float: left;
    display: inline-block;
    
    &:first-child {
      order: 2;
    }
  }
  
  .menu-name-main-menu > ul > li.header-menu-column > a {
    display: none;
  }
  
  .menu-name-main-menu .menu a {
    color: color(zomegray);
    text-decoration: none;
    font-size: 1.2rem;
    line-height: 2.6rem;
    font-weight: 400;
    
    @media only screen and (min-width: $tablet-min) {
      &:hover {
        color: color(zomegreen);
      }
    }
    
  }
  
  a.footer-logo {
    width: 100px;
    float: left;
    
    .zme-fill {
      fill: #818887;
      -webkit-transition: .2s ease-in-out;
      -moz-transition: .2s ease-in-out;
      -o-transition: .2s ease-in-out;
      -ms-transition: .2s ease-in-out;
    }
    
    .o-fill {
      fill: #ABCE65;
      -webkit-transition: .2s ease-in-out;
      -moz-transition: .2s ease-in-out;
      -o-transition: .2s ease-in-out;
      -ms-transition: .2s ease-in-out;
    }
  }
  
  a.twitter-link {
    float: right;
    display: block;    
    text-decoration: none;
    color: color(zomegray);
    font-weight: 400;
    
    span {  
      font-size: 1.2rem;
      line-height: 2.6rem;
      display: inline-block;
      vertical-align: middle;
    }
    
    i {
      font-size: 2.4rem;
      line-height: 2.6rem;
      margin-left: 5px;
      color: #cdcfcf;
      vertical-align: middle;
    }
    
    &:hover {
      span, i {
        color: color(zomegreen);
      }
    }
  }
  
  .footer-copyright {
    color: color(zomegray);
    margin-top: 20px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    text-transform: uppercase;
    
    a {
      color: color(zomegray);
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  
}