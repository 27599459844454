
// Dependencies.
@import 'init/variables';

.bg-zomegreen {
  background: color(zomegreen);
}
.bg-zomegray {
  background: color(zomegray);
}
.bg-zomegraydark {
  background: color(zomegraydark);
}
.bg-zomegray10 {
  background: color(zomegray10);
}
.bg-zomegreen10 {
  background: color(zomegreen10);
}

.column-table-container {
  width: 100%;
  display: block;

  @media only screen and (min-width: $tablet-min) {
    display: table;
    table-layout: fixed;
    height: 100%;
  }
}

.column-table-row {
  display: block;
  
  @media only screen and (min-width: $tablet-min) {
    display: table-row;
    overflow: auto;
  }
}

.column-table-cell {
  display: block;
  
    @media only screen and (min-width: $tablet-min) {
      display: table-cell;
      vertical-align: top;
    }
}

.page-width.half-left {
  float: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 40px 30px 50px;
  
  @media only screen and (min-width: $tablet-min) {
    float: right;
    padding: 40px 70px 50px;
  }

  @media only screen and (min-width: $desktop-min) {
    width: 600px;
    padding: 40px 70px 50px;
  }
}

.page-width.half-right {
  float: none;
  clear: both;
  width: 100%;
  height: 100%;
  padding: 40px 30px 50px;
  
  @media only screen and (min-width: $tablet-min) {
    float: left;
    padding: 40px 70px 50px;
  }
  
  @media only screen and (min-width: $desktop-min) {
    width: 600px;
    padding: 50px 70px 50px;
  }
}

.dotted-divider.green {
  
  @media only screen and (max-width: $tablet-min - 1) {
    border-bottom: 2px dotted color(zomegreen);
    position: absolute;
    bottom: 0px;
    right: 30px;
    left: 30px;
  }
  
  @media only screen and (min-width: $tablet-min) {
    border-right: 2px dotted color(zomegreen);
    position: absolute;
    top: 40px;
    bottom: 40px;
    right: 0;
  }
}