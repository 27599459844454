// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.header,
%header {
  @extend %clearfix;
  
  height: 150px;
  
  @media only screen and (min-width: $tablet-min) {
    height: 200px;
  }
  

  // Wrapping link for logo.
  &__logo {
    margin: 0;
    padding: 0;
    width: 140px;
    position: absolute;
    top: 72px;
    right: 30px;
    
    @media only screen and (min-width: $tablet-min) {
      top: 50px;
      right: 65px;
    }
    
    .zme-fill {
      fill: #818887;
      -webkit-transition: .2s ease-in-out;
      -moz-transition: .2s ease-in-out;
      -o-transition: .2s ease-in-out;
      -ms-transition: .2s ease-in-out;
    }
    
    .o-fill {
      fill: #ABCE65;
      -webkit-transition: .2s ease-in-out;
      -moz-transition: .2s ease-in-out;
      -o-transition: .2s ease-in-out;
      -ms-transition: .2s ease-in-out;
    }
    
  }
  
  .menu-name-main-menu {
    display: none;
    
    @media only screen and (min-width: $tablet-min) {
       display: block;
       position: absolute;
       top: 50px;
       right: 200px;
    }
  }
  
  .menu-name-main-menu ul {
    margin: 0;
    padding: 0;
  }
  
  .menu-name-main-menu > ul > li.header-menu-column {
    margin-bottom: 20px;
    
    @media only screen and (min-width: $tablet-min) {
      float: left;
      width: 200px;
    }
  }
  
  .menu-name-main-menu > ul > li.header-menu-column > a {
    display: none;
  }
  
  .menu-name-main-menu .menu a {
    color: #fff;
    text-decoration: none;
    font-size: 1.8rem;
    line-height: 4rem;
    
    &.active { color: #fff; }
    
    @media only screen and (min-width: $tablet-min) {
      color: color(zomegray);
      font-size: 1.4rem;
      line-height: 2.6rem;
      font-weight: 400;
      
      &.active {
        color: color(zomegraydark);
        }
      &:hover {
        color: color(zomegreen);
      }
    }
  }
  
  
  button.mobile-nav-trigger {
    background: transparent;
    border: none;
    width: 90px;
    height: 90px;
    padding: 20px 30px 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    -webkit-appearance: none;
    
    &:focus {outline:0;}
    
    @media only screen and (min-width: $tablet-min) {
       display: none;
    }
  
    span {
      display: block;
      width: 30px;
      height: 1px;
      background: color(zomegray);
      margin-bottom: 9px;
      -webkit-transition: .2s ease-in-out;
      -moz-transition: .2s ease-in-out;
      -o-transition: .2s ease-in-out;
      -ms-transition: .2s ease-in-out;
    }
  }
  
  .mobile-nav-overlay {
    
  }
  
  .mobile-nav-overlay.open {
    
    @media only screen and (max-width: $tablet-min) {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 999;
      height: 100%;
      
      .mobile-nav-overlay-bg {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        height: 100%;
        background: color(zomegreen);
        -webkit-transition: .2s ease-in-out;
        -moz-transition: .2s ease-in-out;
        -o-transition: .2s ease-in-out;
        -ms-transition: .2s ease-in-out;
      }
      
      .zme-fill {
        fill: #fff;
      }
      .o-fill {
        fill: #fff;
      }
      
      .menu-name-main-menu {
        display: block;
        position: absolute;
        text-align: right;
        top: 160px;
        right: 30px; 
      }
  
  
      .mobile-nav-trigger {
        
        span {
          background: #fff;
        }
    
        span:nth-of-type(1n) {
          width: 40px;
          transform: rotate(-45deg) translate(-10px, 0);
        }
    
        span:nth-of-type(3n) {
          width: 40px;
          transform: rotate(45deg) translate(-14px, -4px);
        }
    
        span:nth-of-type(2n) {
          opacity: 0;
        }
      }
    }
  }
}
