// Dependencies.
@import 'init/variables';
    
.quote-group {
  margin-bottom: 30px;
  text-align: center;
  
  .quote-body {
    font-size: 1.8rem;
    line-height: 2.8rem;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    margin-bottom: 30px;
    
    p {
      margin-bottom: .9rem;
    }
    
    &:before {
      display: block;
      content: '';
      width: 44px;
      height: 34px;
      background: url(../images/quote_green.svg) no-repeat;
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }
  
  .quote-attr {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    
    &.with-photo {
      text-align: left;
      padding: 18px 0 0 92px;
    }
    
    
    .quote-image {
      width: 72px;
      position: absolute;
      left: 0;
      top: 0;
    }
    
    .quote-name {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 700;
      color: color(zomegreen);
      text-transform: uppercase;
      white-space: nowrap;
    }
    
    .quote-title {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 400;
      color: color(zomegray);
    }
    
  }
  
  
  
  @media only screen and (min-width: $tablet-min) {
    max-width: 600px;
    margin: 0 auto;
  }
}