// Dependencies.
@import 'init/variables';

.team-list-item {
  margin-bottom: 40px;
}

.team-list-photo {
  width: 144px;
  height: 144px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 2rem;
  
  img {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
}

.team-list-name {
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  color: color(zomegreen);
}

.team-list-title {
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: .9rem;
  font-weight: 400;
}

.team-list-bio {
  font-size: 1.6rem;
  line-height: 2rem;
}

#home-team .views-responsive-grid,
#team-page-list .views-responsive-grid {
  
  @media only screen and (min-width: $tablet-min) {
  display: table;
  margin: 0 -20px;
  
  .views-row {
    display: table-row;
    table-layout: fixed;
  }
  
  .views-column {
    vertical-align: top;
    display: table-cell;
    table-layout: fixed;
    width: 1%;
    padding: 0 20px;
    }
  }
}

#home-team .views-responsive-grid {
  
  
  @media only screen and (max-width: $tablet-min - 1) {
    // settings for iosslider on mobile width
    width: 100%;
    height: 450px;
      
    //slider
    .views-row {
      width: 100%;
      height: 100%;
    }
    
    //slide
    .views-column {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
  
  @media screen and (min-width: 0px) and (max-width: 320px) {
    height: 540px;
  }
  
  @media screen and (min-width: 321px) and (max-width: 375px) {
    height: 500px;
  }
  
}



@media only screen and (max-width: $tablet-min - 1) {
  #home-team .nav-dots {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    
    span {
      display: block;
      width: 8px;
      height: 8px;
      margin-right: 6px;
      border: 1px solid color(zomegreen);
      float: left;
      
      &.selected {
        background: color(zomegreen);
      }
    }
  }
}