

body.node-type-contact-us #cta-boxes {
  display: none;
}


#cta-box-email-signup {
  
  h2 {
    color: #fff;
  }
  
  p {
    font-weight: 400;
  }
  
  input#fieldEmail {
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 3rem;
    padding: 10px 30px;
    margin: 0;
    margin-top: 2rem;
    color: color(zomegray);
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
  }
  
  button.webform-submit {
    display: block;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    height: 60px;
    padding: 0 30px;
    font-size: 1.6rem;
    line-height: 5.5rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    margin-top: 2rem;
    background: none;
    border-radius: 0;
    -webkit-appearance: none;
    
    @media only screen and (min-width: $tablet-min) {
      &:hover {
        color: color(zomegraydark);
        border-color: color(zomegray);
      }
    }
  }
  
}

#cta-box-contact-us {
  color: #fff;
  
  h2 {
    color: #fff;
  }
  
  p {
    font-weight: 400;
  }
  
  .btn-contact {
    display: block;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    height: 60px;
    padding: 0 30px;
    font-size: 1.6rem;
    line-height: 5.5rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    border: 1px solid color(zomegreen);
    margin-top: 2rem;
    
    @media only screen and (min-width: $tablet-min) {
      &:hover {
        color: color(zomegreen);
      }
    }
  }
  
}