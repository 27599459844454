// Dependencies.
@import 'init/variables';

#team-page {
  
  .team-intro {
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-bottom: 30px;
  
    p {
      margin-bottom: 1rem;
    }
    
    @media only screen and (min-width: $tablet-min) {
      width: 350px;
      float: left;
    }
  }
  
}