// Dependencies.
@import 'init/variables';


.contact-intro {
  font-size: 1.8rem;
  line-height: 2.8rem;
  margin-bottom: 30px;

  p {
    margin-bottom: 1rem;
  }
  
  @media only screen and (min-width: $tablet-min) {
    width: 350px;
    float: left;
  }
  
  a {
    text-decoration: none;
    color: color(zomegreen);
  }
}

.contact-information {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  
  @media only screen and (min-width: $tablet-min) {
    width: 240px;
    float: right;
  }
  @media only screen and (min-width: $desktop-min) {
    margin-right: 200px;
  }
  
  p {
    margin-bottom: .9rem;
  }
  
  a.tel {
    color: color(zomegraydark);
    text-decoration: none;    
  }
  
  a.email {
    font-weight: 700;
    color: color(zomegreen);
    text-decoration: none;
    
    @media only screen and (min-width: $tablet-min) {
      &:hover {
        text-decoration: underline;
      }
    }
    
  }
}