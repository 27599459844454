

.page-width {
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding: 40px 30px 50px;
  
  @media only screen and (min-width: $tablet-min) {
    padding: 40px 70px 70px;
  }
  
  @media only screen and (min-width: $desktop-min) {
    width: 1200px;
  }
  
}

.page-width.messages-tabs {
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding: 0px 30px;
  
  @media only screen and (min-width: $tablet-min) {
    padding: 0px 70px;
  }
  
  @media only screen and (min-width: $desktop-min) {
    width: 1200px;
  }
  
}

body.page-user {
  
  form#user-login,
  .profile {
    display: block;
    width: 100%;
    padding: 40px 30px;
    clear: both;
    
    @media only screen and (min-width: $tablet-min) {
      padding: 0px 70px;
    }

    @media only screen and (min-width: $desktop-min) {
      width: 1200px;
      margin: 0 auto;
      padding: 40px 70px;
    }

    .form-item {
        margin-bottom: 20px;
    }
  }
}

.page-width.no-pad-top {
  padding-top: 0;
}

.page-width.no-pad-bottom {
  padding-bottom: 0;
}

hr.dotted-rule.green {
  margin: 50px 0;
  border: none;
  border-top: 2px dotted color(zomegreen);
}
