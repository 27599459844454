// Dependencies.
@import 'init/variables';
  

h2.minor {
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: color(zomegraydark);
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 1rem;
}

ul.checklist {
  font-size: 1.8rem;
  line-height: 2.4rem;
  list-style: none;
  padding: 0;

  li {
    padding-left: 2.4rem;
    margin-bottom: .5rem;
  }
  li:before {
    content: "\f00c"; /* FontAwesome Unicode */
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: color(zomegreen);
    display: inline-block;
    margin-left: -2.4rem; /* same as padding-left set on li */
    width: 2.4rem; /* same as padding-left set on li */
  }
}

.page-col-half {
  margin-bottom: 40px;
  font-size: 1.8rem;
  line-height: 2.8rem;

  p {
    margin-bottom: 1.4rem;
  }
  
  &.pad-left {
    @media only screen and (min-width: $tablet-min) {
      padding-left: 70px;
    }
  }
  
  &.pad-right {
    @media only screen and (min-width: $tablet-min) {
      padding-right: 70px;
    }
  }
}

.page-col-half.left {
  @media only screen and (min-width: $tablet-min) {
    width: 50%;
    float: left;
  }
}

.page-col-half.right {
  @media only screen and (min-width: $tablet-min) {
    width: 50%;
    float: right;
  }
}