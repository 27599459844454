// Dependencies.
@import 'init/variables';
    
.about-timeline {
  padding: 40px 0 0;
  
  @media only screen and (max-width: $tablet-min - 1) {
    margin: 0 -30px;
  }
  
  h2 {
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: color(zomegraydark);
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    padding: 0 30px;
    margin-bottom: 0px;
  }
  
  .timeline-container {
    padding: 40px 0px 60px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    
    &::-webkit-scrollbar{-webkit-appearance:none;height:5px;background-color:rgba(0,0,0,.1);}
    &::-webkit-scrollbar-thumb{background-color: color(zomegreen);}
    
    .timeline-scroll-wide {
      padding: 0 30px;
      
      &.count-14 { width: calc(2240px + 60px); }
    }
    
    .timeline-dotted-green {
      border-top: 2px dotted color(zomegreen);
      margin-bottom: 20px;
    }
    
    .milestone-group {
      float: left;
      width: 130px;
      margin-right: 30px;
    }
    
    .milestone-date {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 400;
      text-transform: uppercase;
    }
    
    .milestone-desc {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 400;
    }
  }
}