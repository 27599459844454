// Dependencies.
@import 'init/variables';

.home-tagline {
  font-size: 2.8rem;
  line-height: 3.3rem;
  color: color(zomegray);
  margin-bottom: 30px;
  
  .page-width {
    padding-top: 0;
    padding-bottom: 0;
  }
  
}

.home-intro {
  padding-top: 40px;
  
  .btn-regular {
    margin-top: 20px;
  }
}

.home-features {
  position: relative;
	width: 100%;
	height: 0;
  @media only screen and (max-width: $tablet-min - 1) {
    min-height: 460px;
  }
  
  @media only screen and (min-width: $tablet-min) {
    /* 39% being the ratio we are maintaining 
    * calculated by looking at the image 
    * ratio: 400/1024 = ~0.39 */
    padding: 0 0 53% 0;
  }
  
  @media only screen and (min-width: 1350px) {
    padding: 0;
    height: 100%;
    max-height: 720px;
  }
}

#home-feature-slider {
  width: 100%;
  height: 1500px;
  max-height: 720px;
    
  .slider {
    width: 100%;
    height: 100%;
  }
  
  .nav-dots {
    position: absolute;
    width: 100%;
    bottom: 20px;
    
    .page-width {
      padding-top: 0;
      padding-bottom: 0;
    }
    
    span {
      display: block;
      width: 8px;
      height: 8px;
      margin-right: 6px;
      border: 1px solid #fff;
      float: left;
      
      &.selected {
        background: #fff;
      }
    }
    
  }
  
  .home-feature-slide {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 720px;
    overflow: hidden;
    
    .page-width {
      height: 100%;
    }
    
    .feature-backround-overlay {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+40,0+40,0.65+100 */
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 40%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 40%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
    }
    
    .feature-background-img {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    
    .feature-background-img img{
      min-height: 100%;
      min-width: 100%;
      width: auto;
      height: auto;
      max-width: none;
      max-height: none;
      position: absolute;
      display: block;
      top: 0%;
      left: 50%;
      -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
      
      @media only screen and (max-width: $tablet-min - 1) {
        max-height: 100%;
      }
    }
    
    .home-feature-info {
      position: absolute;
      bottom: 40px;
      right: 30px;
      text-align: right;
      max-width: 350px;
      
      @media only screen and (min-width: $tablet-min) {
        right: 70px;
      }
      
      .home-feature-slide-title {
        color: #fff;
        font-size: 2.8rem;
        line-height: 3.3rem;
        margin-bottom: .6rem;
      }
      
      .home-feature-slide-teaser {
        color: #fff;
        font-size: 1.6rem;
        line-height: 2.0rem;
        margin-bottom: .6rem;
        font-weight: 400;
      }
      
      a.home-feature-button {
        display: inline-block;
        box-sizing: border-box;
        min-width: 150px;
        text-align: center;
        height: 40px;
        padding: 0 30px;
        font-size: 1.6rem;
        line-height: 3.6rem;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        border: 1px solid color(zomegreen);
        margin-top: 1rem;
        
        @media only screen and (min-width: $tablet-min) {
          &:hover {
            color: color(zomegreen);
          }
        }
      }
    }    
  }
}