// Dependencies.
@import 'init/variables';


a.btn-regular {
  display: inline-block;
  box-sizing: border-box;
  min-width: 150px;
  text-align: center;
  height: 40px;
  padding: 0 30px;
  font-size: 1.6rem;
  line-height: 3.6rem;
  text-transform: uppercase;
  font-weight: 700;
  color: color(zomegray);
  text-decoration: none;
  border: 1px solid color(zomegreen);
  margin-top: 1rem;
  
  @media only screen and (min-width: $tablet-min) {
    &:hover {
      color: color(zomegreen);
    }
  }
  
  &.right {
    float: right;
  }
}