

.caption {
  padding-top: 1rem;
  margin-bottom: 2rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-style: italic;
  
  p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-style: italic;
    margin-bottom: .6rem;
  }
}