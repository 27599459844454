// Dependencies.
@import 'init/variables';

#box-home-awards .views-responsive-grid {
  display: table;
  
  .views-row {
    display: table-row;
    table-layout: fixed;
  }
  
  .views-column {
    display: table-cell;
    table-layout: fixed;
    width: 1%;
    vertical-align: middle;
  }
  
  a.award-list-item {
    display: block;
    margin: 20px 0;
    
    @media only screen and (min-width: $tablet-min) {
      
      &:hover {
        opacity: .8;
      }
    }
  }
  
  .views-column-1 {
    padding-right: 10px;
  }
  
  .views-column-2 {
    padding-left: 10px;
  }
}

#awards-index .views-responsive-grid {
  display: table;
  margin: 0 -20px;
  
  .views-row {
    display: table-row;
    table-layout: fixed;
  }
  
  .views-column {
    display: inline-block;
    width: 50%;
    vertical-align: middle;
    padding: 0 20px;
    
    @media only screen and (max-width: $tablet-min - 1) {

    }
    
    @media only screen and (min-width: $tablet-min) {
      display: table-cell;
      table-layout: fixed;
      width: 1%;
      padding: 0px 20px;
    }
    
  }
  
  a.award-list-item {
    display: block;
    margin: 20px 0;
    
    @media only screen and (min-width: $desktop-min) {
      &:hover {
        opacity: .8;
      }
    }
  }
}