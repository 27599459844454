// Dependencies.
@import 'init/variables';


.form-wrapper {
  margin: 0 auto;
  @media only screen and (min-width: $tablet-min) {
    max-width: 728px;
   }
}

#webform-client-form-34 {
  color: color(zomegray);
  
  @media only screen and (min-width: $tablet-min) {
    margin: 0 -10px;
  }
  
  p {
    font-weight: 400;
  }
  
  .webform-component--required-note p {
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: right;
  }
  
  .form-item {
    margin: 20px 0 10px;
    clear: both;
    
    @media only screen and (min-width: $tablet-min) {
      padding: 0 10px;
    }
    
    .form-item {
      padding: 0;
      margin: 10px 0;
    }
  
    &.form-col-half {
      @media only screen and (min-width: $tablet-min) {
        width: 50%;
        margin: 10px 0;
        float: left;
        clear: none;
      }
    }
    &.form-type-checkbox {
      margin: 10px 0;
      padding: 0;
    }
    
    &.webform-component--newsletter-sign-up {
      padding-top: 20px;
      margin-bottom: 40px;
    }
    
    .description {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 3rem;
    }
  }
  
  input[type="text"],
  input[type="email"] {
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 3rem;
    padding: 10px 15px;
    margin: 0;
    color: color(zomegraydark);
    -webkit-appearance: none;
    border: none;
    border: 1px solid #e6e7e7;
    border-radius: 0;
  }
  
  textarea {
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding: 10px 15px;
    margin: 0;
    color: color(zomegraydark);
    -webkit-appearance: none;
    border: none;
    border: 1px solid #e6e7e7;
    border-radius: 0;
  }
  
  input[type='checkbox'] {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  
  input[type='checkbox'] + label.option {
    padding-left: 40px;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;
    font-size: 1.8rem;
    line-height: 3rem;
    font-weight: 400;
  }

  input[type='checkbox'] + label.option {
      background-image: url(/sites/all/themes/zomepower/images/form_checkbox.png);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
  input[type='checkbox']:checked + label.option {
    background-position: 0 -30px;
  }
  
  .webform-component--newsletter-sign-up input[type='checkbox'] + label.option {
    padding-top: 5px;
    line-height: 2.2rem;
  }
  
  select {
    width: 100%;
    margin: 0;
    border: 1px solid #c9c9c9;
    background: #fff;
    padding: 0 50px 0 15px;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.2rem;
    color: color(zomegraydark);
    border: 1px solid #e6e7e7;
    border-radius: 0;
    height: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(/sites/all/themes/zomepower/images/form_select.png) right no-repeat #fff;
  }
  
  input[type="submit"] {
    display: block;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    height: 60px;
    padding: 0 30px;
    font-size: 1.6rem;
    line-height: 5.5rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    margin: 0 auto;
    margin-top: 2rem;
    background: color(zomegreen);
    border: none;
    -webkit-appearance: none;
    border-radius: 0;
    
    @media only screen and (min-width: $tablet-min) {
      max-width: 354px;
      &:hover {
        background: color(zomegray);
      }
    }
  }
  
}