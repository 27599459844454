// Dependencies.
@import 'init/variables';

.feature-table {
  
  tr {
    border-bottom: 1px solid color(zomegray);
    
    th {
      font-size: 1.4rem;
      line-height: 2rem;
      text-transform: uppercase;
      text-align: left;
      padding: 10px 0;
      color: color(zomegray);
      
      &.feature-col-title {
        text-align: center;
      }
    }
    
    td.feature-check {
      width: 20%;
      text-align: center;
      color: color(zomegreen);
      
      &:nth-of-type(odd) {
        background: color(zomegray10);
      }
    }
    
  }
  
  
}