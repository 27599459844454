// Dependencies.
@import 'init/variables';
    
.about-stats {
  margin-bottom: 30px;
  
  .stat-group {
    display: inline-block;
    margin-right: 40px;
    margin-bottom: 30px;
    
    .stat-num {
      color: color(zomegreen);
      font-size: 9.6rem;
      line-height: 7.2rem;
      margin-bottom: .9rem;
    }
    
    .stat-label {
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: color(zomegray);
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}